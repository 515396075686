import React, { useState, useContext, useEffect, useRef } from "react";
import PageLayout from "../Layouts/PageLayout";
import { Redirect } from "react-router-dom";
import Modal from "react-modal";
import { withRouter, useLocation } from "react-router-dom";
// import ReactAudioPlayer from "react-audio-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "video-react/dist/video-react.css"; // import css
import {
    Player,
    ControlBar,
    PlayToggle,
    ReplayControl,
    ForwardControl,
    BigPlayButton,
    PlaybackRateMenuButton,
} from "video-react";
import { getCourseById, lessonCompletionUpdate } from "../services/student";
import { showError, showSuccess } from "../Utils/helper";
import Tutor from "../AI/Tutor";
import AiFeatures from "../AI/AiFeatures";
import Loader from "../Common/Loader";
import { ToastContainer } from "react-toastify";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { UserContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import PDFReader from "../pdfReader";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import Quiz from "./Quiz";
import { postComments } from "../services/discussionForum";
import _uniqueId from "lodash/uniqueId";
import { useTranslation } from "react-i18next";
import getTranslationFromMany from "../../services/helper";
import LiveSession from "./LiveSession";
import { BsChatTextFill } from "react-icons/bs";

const CourseLearning = (props) => {
    const { t, i18n } = useTranslation();
    const playerRef = useRef(null);
    const { loggedIn } = useContext(UserContext);
    const [modalIsOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [view, setView] = useState("");
    const [videoState, setVideoState] = useState(null);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [counter, setCounter] = useState(false);
    const [videoEnd, setVideoEnd] = useState(false);
    const [check, setCheck] = useState([]);
    const [question, setQuestion] = useState("");
    const course_id = props.location.state;
    const [isDownloadable, setIsDownloadable] = useState(false);
    const [uniqueId, setUniqueId] = useState("");
    const [instructorToAsk, setInstructorToAsk] = useState(null);
    const [height, setHeight] = useState(0);
    const heightRef = useRef(null);
    const [openTutor, setOpenTutor] = useState(false)

    function openModal() {
        setIsModalOpen(true);
    }
    function closeModal() {
        setIsModalOpen(false);
    }
    useEffect(() => {
        if (!loggedIn) {
            <Redirect to="/" />;
        }
    }, [loggedIn]);
    async function toGetCourseById() {
        setLoading(true);
        const resp = await getCourseById(course_id);
        if (resp.success) {
            setData(resp.data);
            if (
                resp?.data?.chapter[0]?.lessons[0]?.media[0]
                    ?.is_downloadable === 1
            ) {
                setIsDownloadable(true);
            }
            setVideoUrl(resp?.data?.chapter[0]?.lessons[0]?.media[0]?.url);
            setCurrentLesson(resp?.data?.chapter[0]?.lessons[0]);
            setView(
                resp?.data?.chapter[0]?.lessons[0]?.quiz?.length > 0
                    ? "quiz"
                    : resp?.data?.chapter[0]?.lessons[0]?.media[0]?.media_type
            );
            setLoading(false);
        } else {
            showError("Somthing went wrong");
            setLoading(false);
        }
    }
    useEffect(() => {
        document.addEventListener("contextmenu", (e) => {
            e.preventDefault();
        });
        toGetCourseById();
    }, []);
    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.subscribeToStateChange((e) => {
                setVideoState(e);
            });
        }
    });
    useEffect(() => {
        if (videoState && videoState?.ended && !videoEnd) {
            lessonCompletionUpdateApi();
            setVideoEnd(true);
        }
    }, [videoState]);
    useEffect(() => {
        if (heightRef.current) {
            setHeight(heightRef.current.clientHeight);
        }
    });
    async function lessonCompletionUpdateApi() {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: currentLesson?.chapter_id,
            lesson_id: currentLesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCounter(false);
            setCheck([...check, resp?.data?.lesson_id]);
            showSuccess(t("alertMessage.lessonCompletedSuccess"));
        } else {
            showError(t("alertMessage.wrong"));
        }
    }
    async function markAsCompleted(_lesson) {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: _lesson?.chapter_id,
            lesson_id:
                view === "quiz" ? _lesson?.id : _lesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCheck([...check, resp?.data?.lesson_id]);
            showSuccess(t("alertMessage.lessonCompleted"));
        } else {
            showError(t("alertMessage.wrong"));
        }
    }
    async function unmarkComplete(_lesson) {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: _lesson?.chapter_id,
            lesson_id:
                view === "quiz" ? _lesson?.id : _lesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCheck(check.filter((item) => item !== resp?.data?.lesson_id));
            showSuccess(t("alertMessage.success"));
        } else {
            showError(t("alertMessage.wrong"));
        }
    }

    async function askDoubt() {
        if (question.length > 0) {
            const resp = await postComments(instructorToAsk, {
                comment: question,
            });
            if (resp.success) {
                showSuccess(t("alertMessage.qAskedSuccess"));
                closeModal();
            } else {
                showError(t("alertMessage.wrong"));
            }
        }
    }

    const handleChooseQAInstructor = (event) => {
        setInstructorToAsk(event.target.value);
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {" "}
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        contentLabel="Example Modal"
                        className="askdoubt_modal"
                        overlayClassName="modal_overlay"
                    >
                        {/* <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <img src={icons.icon_close} alt="" />
            </button> */}
                        <div className="modal-content">
                            <div className="modal-body">
                                <div
                                    className="lrf_modal"
                                    dir={i18n.language == "ar" ? "rtl" : "ltr"}
                                >
                                    <h2 className="text-left">
                                        {t("common.askYourQuestion")}
                                    </h2>
                                    <div className="form-group">
                                        <div>
                                            <label>Select an instructor</label>
                                            <br />
                                            <select
                                                onChange={
                                                    handleChooseQAInstructor
                                                }
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                    hidden
                                                >
                                                    Select an Instructor
                                                </option>
                                                {data?.instructors?.map(
                                                    (instructor) => (
                                                        <option
                                                            key={instructor.id}
                                                            value={
                                                                instructor.id
                                                            }
                                                        >
                                                            {
                                                                instructor.full_name
                                                            }
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                        <div className="form_input">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder={t(
                                                    "common.typeMessage"
                                                )}
                                                value={question}
                                                onChange={(e) =>
                                                    setQuestion(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="course_btns mt_30"
                                        dir={
                                            i18n.language == "ar"
                                                ? "rtl"
                                                : "ltr"
                                        }
                                    >
                                        <button
                                            className="btn_secondary big_btn"
                                            data-dismiss="modal"
                                        >
                                            <span
                                                className="btn_text"
                                                onClick={closeModal}
                                            >
                                                {t("common.cancel")}
                                            </span>
                                        </button>
                                        <button
                                            className="btn_primary big_btn"
                                            onClick={() => askDoubt()}
                                        >
                                            <span className="btn_text">
                                                {t("common.submit")}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <PageLayout>
                        <div
                            className="container-fluid ptb_50"
                            dir={i18n.language == "ar" ? "rtl" : "ltr"}
                        >
                            <h1 className="page_title text-left">
                                {data?.name}
                            </h1>
                            {/* <h2 className="topic_name">Course Topic Name</h2> */}
                            <div className="course_wrapper">
                                {view === "video" ? (
                                    <div className="course_video">
                                        <Player
                                            src={
                                                process.env.REACT_APP_ASSETS +
                                                videoUrl
                                            }
                                            ref={playerRef}
                                        >
                                            <BigPlayButton position="center" />
                                            <ControlBar autoHide={false}>
                                                <PlayToggle />
                                                <ReplayControl
                                                    seconds={10}
                                                    order={2.2}
                                                />
                                                <ForwardControl
                                                    seconds={10}
                                                    order={3.2}
                                                />
                                                <PlaybackRateMenuButton
                                                    rates={[2, 1, 1.75, 1.5]}
                                                />
                                            </ControlBar>
                                        </Player>
                                    </div>
                                ) : view === "pdf" ? (
                                    <PDFReader
                                        videoUrl={videoUrl}
                                        isDownloadable={isDownloadable}
                                    />
                                ) : view === "image" ? (
                                    <img
                                        src={
                                            process.env.REACT_APP_ASSETS +
                                            videoUrl
                                        }
                                        class="responsive-image"
                                        alt={data?.name}
                                    />
                                ) : view === "audio" ? (
                                    <div className="course_video">
                                        <AudioPlayer
                                            autoPlay
                                            src={
                                                process.env.REACT_APP_ASSETS +
                                                videoUrl
                                            }
                                            onEnded={() =>
                                                lessonCompletionUpdateApi()
                                            }
                                            showSkipControls={false}
                                            loop={false}
                                        />
                                    </div>
                                ) : view === "live-session" ? (
                                    <>
                                        <LiveSession
                                            name={currentLesson?.name}
                                            url={
                                                currentLesson?.live_session[0]
                                                    ?.URL
                                            }
                                            date={
                                                currentLesson?.live_session[0]
                                                    ?.session_date
                                            }
                                        />
                                    </>
                                ) : (
                                    <Quiz
                                        key={uniqueId}
                                        quiz={currentLesson}
                                        course_id={course_id}
                                        markAsCompleted={markAsCompleted}
                                        uniqueId={uniqueId}
                                    />
                                )}

                                <div className="course_topic">
                                    <div
                                        className="topic_box ptb_30"
                                        id="myDIV"
                                        ref={heightRef}
                                    >
                                        <h3 className="text-center mb_20">
                                            {t("course.courseContent")}
                                        </h3>
                                        <Accordion>
                                            {data?.chapter?.map((_chapter) => {
                                                return (
                                                    <AccordionItem
                                                        uuid={_chapter?.id}
                                                    >
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                {t("dynamic", {
                                                                    en: _chapter?.name,
                                                                    ar: _chapter
                                                                        ?.translation
                                                                        ?.value
                                                                        ? _chapter
                                                                              ?.translation
                                                                              ?.value
                                                                        : _chapter?.name,
                                                                })}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className="accordion-item-panel">
                                                            {_chapter?.lessons?.map(
                                                                (_lesson) => {
                                                                    return (
                                                                        <div
                                                                            className={`topic_row ptb_10 plr_30 ${
                                                                                currentLesson?.id ===
                                                                                _lesson?.id
                                                                                    ? "active_chapter"
                                                                                    : "inactive"
                                                                            }`}
                                                                        >
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={
                                                                                    _lesson?.id
                                                                                }
                                                                                checked={
                                                                                    _lesson?.student_update_lesson !==
                                                                                        null ||
                                                                                    check.includes(
                                                                                        _lesson?.id
                                                                                    )
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                onClick={() => {
                                                                                    if (
                                                                                        document.getElementById(
                                                                                            _lesson?.id
                                                                                        )
                                                                                            .checked !==
                                                                                        false
                                                                                    ) {
                                                                                        markAsCompleted(
                                                                                            _lesson
                                                                                        );
                                                                                    } else {
                                                                                        unmarkComplete(
                                                                                            _lesson
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <div
                                                                                className="topic_info"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        _lesson?.type ===
                                                                                            "quiz" ||
                                                                                        _lesson?.type ===
                                                                                            "live-session"
                                                                                    ) {
                                                                                        setCurrentLesson(
                                                                                            _lesson
                                                                                        );
                                                                                        setView(
                                                                                            _lesson?.type
                                                                                        );
                                                                                    } else {
                                                                                        setVideoUrl(
                                                                                            _lesson
                                                                                                ?.media[0]
                                                                                                ?.url
                                                                                        );
                                                                                        setCurrentLesson(
                                                                                            _lesson
                                                                                        );
                                                                                        setVideoEnd(
                                                                                            false
                                                                                        );
                                                                                        setView(
                                                                                            _lesson
                                                                                                ?.media[0]
                                                                                                .media_type
                                                                                        );
                                                                                        setIsDownloadable(
                                                                                            _lesson
                                                                                                ?.media[0]
                                                                                                .is_downloadable
                                                                                        );
                                                                                    }
                                                                                    setUniqueId(
                                                                                        "Prefixing-" +
                                                                                            _lesson?.id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <h6>
                                                                                    {t(
                                                                                        "dynamic",
                                                                                        {
                                                                                            en: _lesson?.name,
                                                                                            ar: _lesson
                                                                                                ?.translation
                                                                                                ?.value
                                                                                                ? _lesson
                                                                                                      ?.translation
                                                                                                      ?.value
                                                                                                : _lesson?.name,
                                                                                        }
                                                                                    )}
                                                                                </h6>
                                                                                <div className="topic_time">
                                                                                    {/*<figure>*/}
                                                                                    {/*  <svg*/}
                                                                                    {/*    id="play_circle-24px"*/}
                                                                                    {/*    width="24"*/}
                                                                                    {/*    height="24"*/}
                                                                                    {/*    viewBox="0 0 24 24"*/}
                                                                                    {/*  >*/}
                                                                                    {/*    <g id="Group_94" data-name="Group 94">*/}
                                                                                    {/*      <rect*/}
                                                                                    {/*        id="Rectangle_457"*/}
                                                                                    {/*        data-name="Rectangle 457"*/}
                                                                                    {/*        width="24"*/}
                                                                                    {/*        height="24"*/}
                                                                                    {/*        fill="none"*/}
                                                                                    {/*      />*/}
                                                                                    {/*    </g>*/}
                                                                                    {/*    <g id="Group_95" data-name="Group 95">*/}
                                                                                    {/*      <path*/}
                                                                                    {/*        id="Path_227"*/}
                                                                                    {/*        className="topic_time_icon"*/}
                                                                                    {/*        data-name="Path 227"*/}
                                                                                    {/*        d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM9.5,16.5v-9l7,4.5Z"*/}
                                                                                    {/*        fill="gray"*/}
                                                                                    {/*      />*/}
                                                                                    {/*    </g>*/}
                                                                                    {/*  </svg>*/}
                                                                                    {/*</figure>*/}
                                                                                    {/*<small>10 min</small>*/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                );
                                            })}
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="course_overview">
                                    <div className="mt_20">
                                        <ul
                                            className="nav nav-tabs mb_50"
                                            id="myTab"
                                            role="tablist"
                                            style={{flexWrap: 'nowrap'}}
                                        >
                                            <li
                                                className="nav-item"
                                                role="presentation"
                                            >
                                                <a
                                                    className="nav-link active"
                                                    id="Overview-tab"
                                                    data-toggle="tab"
                                                    href="#Overview"
                                                    role="tab"
                                                    aria-controls="Overview"
                                                    aria-selected="true"
                                                >
                                                    {t("common.overview")}
                                                </a>
                                            </li>
                                            <li
                                                className="nav-item"
                                                role="presentation"
                                            >
                                                <a
                                                    className="nav-link"
                                                    id="revision-tab"
                                                    data-toggle="tab"
                                                    href="#revision"
                                                    role="tab"
                                                    aria-controls="revision"
                                                    aria-selected="false"
                                                >
                                                    {t("aifeatures.revision")}
                                                </a>
                                            </li>
                                            <li
                                                className="nav-item"
                                                role="presentation"
                                            >
                                                <a
                                                    className="nav-link"
                                                    id="qaa-tab"
                                                    data-toggle="tab"
                                                    href="#qaa"
                                                    role="tab"
                                                    aria-controls="qaa"
                                                    aria-selected="false"
                                                >
                                                    {t("common.Q&A")}
                                                </a>
                                            </li>
                                        </ul>
                                        <div
                                            className="tab-content"
                                            id="myTabContent"
                                        >
                                            <div
                                                className="tab-pane fade show active"
                                                id="Overview"
                                                role="tabpanel"
                                                aria-labelledby="Overview-tab"
                                            >
                                                <h3>
                                                    {t("common.aboutCourse")}
                                                </h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t("dynamic", {
                                                            en: data?.description?.replace(
                                                                /['"]+/g,
                                                                ""
                                                            ),
                                                            ar: getTranslationFromMany(
                                                                data?.translation,
                                                                "description",
                                                                data?.description?.replace(
                                                                    /['"]+/g,
                                                                    ""
                                                                )
                                                            ),
                                                        }),
                                                    }}
                                                />

                                                <h3 className="mb_10 mt_20">
                                                    {t(
                                                        "common.learningObjectives"
                                                    )}
                                                </h3>
                                                <ul className="circle_tick_list">
                                                    {data?.course_objectives?.map(
                                                        (obj) => (
                                                            <li key={obj?.id}>
                                                                {t("dynamic", {
                                                                    en: obj?.objective,
                                                                    ar: obj
                                                                        ?.translation
                                                                        ?.value
                                                                        ? obj
                                                                              ?.translation
                                                                              ?.value
                                                                        : obj?.objective,
                                                                })}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>

                                            <div
                                                className="tab-pane fade"
                                                id="qaa"
                                                role="tabpanel"
                                                aria-labelledby="qaa-tab"
                                            >
                                                <Accordion>
                                                    {data?.question_answers?.map(
                                                        (_item) => (
                                                            <AccordionItem>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        {
                                                                            _item?.question
                                                                        }
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <p>
                                                                        {
                                                                            _item?.answer
                                                                        }
                                                                    </p>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        )
                                                    )}
                                                </Accordion>

                                                <div
                                                    className="mt_30 content_link"
                                                    onClick={openModal}
                                                >
                                                    {t("common.anyQuestions")}{" "}
                                                    <a
                                                        className="cursor_pointer"
                                                        style={{
                                                            color: "#1E97FF",
                                                        }}
                                                    >
                                                        {t(
                                                            "common.messageDirectly"
                                                        )}
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="revision"
                                                role="tabpanel"
                                                aria-labelledby="revision-tab"
                                            >
                                                <AiFeatures course_id={course_id} course={data?.name}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                height: height - 1000 + "px",
                            }}
                            className="fixing_div"
                        />
                        <ToastContainer />
                    </PageLayout>
                </>
            )}
            <BsChatTextFill className="chatbot-icon" onClick={() => setOpenTutor(!openTutor)}/>
            {openTutor ?
              <Tutor course_id={course_id} course={data?.name}/>
            :
              null
            }
        </>
    );
};

export default withRouter(CourseLearning);
